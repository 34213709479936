body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fffcf2;
  font-family: 'Noto Sans Mono', monospace;
  color: #252422;
  overflow: hidden;
}

.logo {
  width: 75%;
  max-width: 500px;
  height: auto;
  max-height: 75vh;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.5));
  transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: transform;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: calc(100vh - env(safe-area-inset-bottom));
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.contact {
  font-size: 24px;
  color: #252422;
  position: absolute;
  bottom: 100px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: transform 0.3s ease;
  text-decoration: none;
}

.contact:hover {
  transform: scale(1.1);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}